import { Injectable } from "@angular/core";
import { Checklist, Response } from "../checklist.interface";
import { ChecklistProgressStateQuery } from "./checklist-progress-state.query";
import { ChecklistProgressStateStore } from "./checklist-progress-state.store";
import { UnsupportedQuestionsService } from "../services/unsupported-questions.service";

@Injectable({ providedIn: "root" })
export class ChecklistProgressStateService {
	constructor(
		private checklistProgressStore: ChecklistProgressStateStore,
		private checklistProgressQuery: ChecklistProgressStateQuery,
		private unsupportedQuestionsService: UnsupportedQuestionsService,
	) {}

	setActiveChecklist = async (checklist: Checklist, activity: "Preopening" | "Closing") => {
		const currentProgress = await this.checklistProgressQuery.checklist$.firstAsync();
		if (currentProgress?.id !== checklist.id) {
			this.checklistProgressStore.clear();
		}

		await this.clearResponsesSetAsUnsupported(checklist);

		checklist.started = new Date().toUTCString();
		checklist.activity = activity;

		this.checklistProgressStore.update({
			checklist: checklist,
		});
	};

	clearResponsesSetAsUnsupported = async (checklist: Checklist) => {
		//clean responses wrongfully marked as unsupported, as they may be supported now
		const responses = await this.checklistProgressQuery.responses$.firstAsync();
		const responsesSetAsNotSupported = responses.filter(
			(response) =>
				response.answers.length == 1 &&
				response.answers[0] === UnsupportedQuestionsService.unsupportedAnswerValue,
		);
		if (responsesSetAsNotSupported.length > 0) {
			//see if theses questions are now supported
			const checklistElements = checklist.pages.flatMap((page) => page.elements);
			responsesSetAsNotSupported.forEach((notSupportedResponse) => {
				const element = checklistElements.find((element) => element.id === notSupportedResponse.question);
				if (this.unsupportedQuestionsService.isElementSupported(element?.type)) {
					this.resetAnswerToQuestion(element.id);
				}
			});
		}
	};

	resetAnswerToQuestion = (question: string) => {
		this.checklistProgressStore.update((state) => {
			const responses = [...state.responses].filter((x) => x.question !== question);

			return {
				responses: responses,
			};
		});
	};

	setResponses = (responses: Response[]) => {
		this.checklistProgressStore.update((state) => {
			return { ...state, responses: responses };
		});
	};
}
