<app-question-template [question]="question" [isAnswered]="isAnswered">
	<div class="checkmark-container">
		<ion-icon *ngIf="isAnswered" name="checkmark-circle-outline"></ion-icon>
	</div>

	<div class="button-container">
		<ion-button shape="round" (click)="scan()">
			<ion-label>{{ "CHECKLISTS__QUESTIONS__SCAN__SCAN" | transloco }}</ion-label>
			<ion-icon name="scan-outline" slot="end"></ion-icon>
		</ion-button>
	</div>
</app-question-template>
