import { Injectable } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { ChecklistProgressStateService } from "../state/checklist-progress-state.service";
import { ChecklistModalComponent } from "./checklist-modal.component";
import { ApiService } from "source/app/configuration/services/api.service";
import { UnsupportedQuestionsService } from "../services/unsupported-questions.service";
import { UnsupportedElementsCheckResult } from "../services/unsupported-questions.service";

@Injectable()
export class ChecklistModalService {
	constructor(
		private modalController: ModalController,
		private unsupportedQuestionsService: UnsupportedQuestionsService,
		private checklistProgressService: ChecklistProgressStateService,
		private apiService: ApiService,
	) {}

	async show(activity: "Preopening" | "Closing"): Promise<boolean> {
		const checklist = await this.apiService.getChecklist(activity);

		const unsupportedQuestionsCheckResult =
			await this.unsupportedQuestionsService.checkForUnsupportedQuestions(checklist);

		if (unsupportedQuestionsCheckResult !== UnsupportedElementsCheckResult.Continue) {
			return false;
		}

		await this.checklistProgressService.setActiveChecklist(checklist, activity);

		const modal = await this.modalController.create({
			cssClass: "checklist-modal",
			component: ChecklistModalComponent,
		});

		await modal.present();
		const result = await modal.onDidDismiss();

		return result.data === ChecklistModalComponent.returnCodes.submitted;
	}
}
