import { FormGroup } from "@angular/forms";
import { map, Observable, startWith } from "rxjs";
import { addHours, isAfter, isBefore } from "date-fns";
import {
	AnswerCondition,
	AnsweredAnswerCondition,
	EqualityAnswerCondition,
	FormAnswer,
	FormQuestion,
	NumberAnswerCondition,
	SmileyAnswerCondition,
	TimeAnswerCondition,
} from "../../checklist.interface";
import { throwExhaustiveError } from "source/app/configuration/errors/exhaustive-error";

/**
 * Evaluates the given answer condition against a form group.
 *
 * @param condition The answer condition to evaluate.
 * @param formGroup The form group containing the answers.
 * @returns An observable that emits a boolean value based on the evaluation of the condition.
 */
export function evaluateAnswerCondition(condition: AnswerCondition, formGroup: FormGroup): Observable<boolean> {
	const formControl = formGroup.controls[condition.left];
	const answerObserveable: Observable<FormAnswer> = formControl.valueChanges;
	const answerValidator = createAnswerValidationFunction(condition);

	return answerObserveable.pipe(
		startWith(formControl.value),
		map(() => answerValidator(formControl.value.question)),
	);
}
function createAnswerValidationFunction(condition: AnswerCondition): (question: FormQuestion) => boolean {
	switch (condition.comparer) {
		case "EQ":
		case "NEQ":
			return createEqualityComparisonFunction(condition);
		case "GT":
		case "GTEQ":
		case "LT":
		case "LTEQ":
			return createNumberComparisonFunction(condition);
		case "MoreThanInPast":
		case "LessThanInPast":
		case "MoreThanInFuture":
		case "LessThanInFuture":
			return createTimeComparisonFunction(condition);
		case "BetterThan":
		case "WorseThan":
		case "NA":
			return createSmileyComparisonFunction(condition);
		case "Answered":
		case "NotAnswered":
			return createAnsweredComparisonFunction(condition);
		default:
			throwExhaustiveError("Unhandled condition", condition);
	}
}

function createEqualityComparisonFunction(condition: EqualityAnswerCondition) {
	return function equalityComparison(question: FormQuestion): boolean {
		if (!question) {
			return false;
		}

		const testValue = Number(condition.right);
		const isNumber = !isNaN(testValue);

		switch (condition.comparer) {
			case "EQ":
				return isNumber ? Number(question.answers) === testValue : question.answers?.includes(condition.right);
			case "NEQ":
				return isNumber ? Number(question.answers) !== testValue : !question.answers.includes(condition.right);
			default:
				throwExhaustiveError("Unhandled comparer", condition.comparer);
		}
	};
}

function createNumberComparisonFunction(condition: NumberAnswerCondition) {
	return function numberComparison(question: FormQuestion): boolean {
		if (!question) {
			return false;
		}

		const testValue = Number(condition.right);
		const answerValue = Number(question.answers[0]);

		switch (condition.comparer) {
			case "GT":
				return answerValue > testValue;
			case "GTEQ":
				return answerValue >= testValue;
			case "LT":
				return answerValue < testValue;
			case "LTEQ":
				return answerValue <= testValue;
			default:
				throwExhaustiveError("Unhandled comparer", condition.comparer);
		}
	};
}

function createAnsweredComparisonFunction(condition: AnsweredAnswerCondition) {
	return function answeredComparison(question: FormQuestion): boolean {
		switch (condition.comparer) {
			case "Answered":
				return question?.answers?.length > 0;
			case "NotAnswered":
				return !question;
			default:
				throwExhaustiveError("Unhandled comparer", condition.comparer);
		}
	};
}

function createSmileyComparisonFunction(condition: SmileyAnswerCondition) {
	return function smileyComparison(question: FormQuestion): boolean {
		if (!question || Number(question.answers[0]) === -1) {
			return false;
		}

		const testValue = Number(condition.right);
		const answerValue = Number(question.answers[0]);

		switch (condition.comparer) {
			case "NA":
				return answerValue === -1;
			case "BetterThan":
				return answerValue > testValue;
			case "WorseThan":
				return answerValue < testValue;
			default:
				throwExhaustiveError("Unhandled comparer", condition.comparer);
		}
	};
}

function createTimeComparisonFunction(condition: TimeAnswerCondition) {
	return function timeComparison(question: FormQuestion): boolean {
		if (!question) {
			return false;
		}

		const selectedDate = new Date(question.answers[0]);
		const answered = new Date(question.answered);
		const pointInPast = addHours(answered, -parseInt(condition.right));
		const pointInFuture = addHours(answered, parseInt(condition.right));

		switch (condition.comparer) {
			case "MoreThanInPast":
				return isBefore(selectedDate, pointInPast);
			case "LessThanInPast":
				return isAfter(selectedDate, pointInPast) && isBefore(selectedDate, answered);
			case "MoreThanInFuture":
				return isAfter(selectedDate, pointInFuture);
			case "LessThanInFuture":
				return isAfter(selectedDate, answered) && isBefore(selectedDate, pointInFuture);
			default:
				throwExhaustiveError("Unhandled comparer", condition.comparer);
		}
	};
}
