import { Component, Input, ChangeDetectorRef, forwardRef } from "@angular/core";
import { ImageAttachment, Question } from "../../../checklist.interface";
import { validate as uuidValidate } from "uuid";
import { StorageService } from "source/app/configuration/services/storage.service";
import { UtilityService } from "source/app/configuration/services/utility.service";
import { TranslocoService } from "@ngneat/transloco";
import { ActionSheetController } from "@ionic/angular";
import { ImageService } from "source/app/configuration/services/image.service";
import { ImageViewerModalService } from "source/app/modals/image-viewer/image-viewer.modal.service";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
	selector: "app-attachments",
	templateUrl: "./attachments.component.html",
	styleUrls: ["./attachments.component.scss"],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => AttachmentsComponent),
			multi: true,
		},
	],
})
export class AttachmentsComponent implements ControlValueAccessor {
	constructor(
		private storageService: StorageService,
		private utilityService: UtilityService,
		private translocoService: TranslocoService,
		private actionSheetController: ActionSheetController,
		private imageService: ImageService,
		private imageViewerModalService: ImageViewerModalService,
		private changeDetector: ChangeDetectorRef,
	) {}

	@Input() question: Question;

	attachments: ImageAttachment[] = [];

	private onAttachmentsChange = () => {
		this.onTouched();
		this.onChange(this.attachments);
		this.changeDetector.markForCheck();
	};

	getImage = async () => {
		const mobaroImage = await this.imageService.getPhotoFromCamera();

		this.attachments.push({
			url: mobaroImage.id,
			cameraRoll: false,
			imageSrc: URL.createObjectURL(mobaroImage.file),
		});
		this.onAttachmentsChange();
	};

	getFromGallery = async () => {
		const galleryPhotos = await this.imageService.getImagesFromGallery();
		galleryPhotos.forEach((photo) => {
			this.attachments.push({
				url: photo.id,
				cameraRoll: true,
				imageSrc: URL.createObjectURL(photo.file),
			});
		});
		this.onAttachmentsChange();
	};

	add = async () => {
		const actionSheet = await this.actionSheetController.create({
			header: this.translocoService.translate("ATTACHMENTS__ADD"),
			mode: "ios",
			buttons: [
				{
					text: this.translocoService.translate("ATTACHMENTS__ADD_CAMERA"),
					data: {
						action: "camera",
					},
				},
				{
					text: this.translocoService.translate("ATTACHMENTS__ADD_GALLERY"),
					data: {
						action: "gallery",
					},
				},
				{
					text: this.translocoService.translate("BASE__CANCEL"),
					role: "cancel",
					data: {
						action: "cancel",
					},
				},
			],
		});

		await actionSheet.present();

		const action = await actionSheet.onWillDismiss();

		if (action.data?.action == "camera") {
			this.getImage();
		}

		if (action.data?.action == "gallery") {
			this.getFromGallery();
		}
	};

	edit = async (attachment: ImageAttachment): Promise<void> => {
		const actionSheet = await this.actionSheetController.create({
			header: this.translocoService.translate("ATTACHMENTS__OPTIONS"),
			mode: "ios",
			buttons: [
				{
					text: this.translocoService.translate("ATTACHMENTS__OPTIONS_VIEW"),
					data: {
						action: "view",
					},
				},
				{
					text: this.translocoService.translate("ATTACHMENTS__OPTIONS_REMOVE"),
					role: "destructive",
					data: {
						action: "delete",
					},
				},
				{
					text: this.translocoService.translate("BASE__CANCEL"),
					role: "cancel",
					data: {
						action: "cancel",
					},
				},
			],
		});

		await actionSheet.present();

		const action = await actionSheet.onWillDismiss();

		if (action.data?.action === "delete") {
			this.attachments = this.attachments.filter((x) => x.url !== attachment.url);
			this.onAttachmentsChange();

			if (uuidValidate(attachment.url)) {
				this.imageService.removeImage(attachment.url);
				URL.revokeObjectURL(attachment.imageSrc);
			}
		}

		if (action.data?.action === "view") {
			let imageUrl = "";

			if (uuidValidate(attachment.url)) {
				imageUrl = attachment.imageSrc;
			} else {
				imageUrl = await this.utilityService.getImageUrl(attachment.url, "medium");
			}

			const modal = await this.imageViewerModalService.create(imageUrl);
			modal.present();
		}
	};

	private loadAttachments = async (attachments: ImageAttachment[]): Promise<void> => {
		if (attachments != undefined) {
			attachments = attachments.map(({ url, cameraRoll }) => ({ url, cameraRoll }));

			const imageSrcPromises = attachments.map(async (attachment) => {
				if (uuidValidate(attachment.url)) {
					const imageBlob: Blob = await this.storageService.get(attachment.url);
					attachment.imageSrc = URL.createObjectURL(imageBlob);
				} else {
					attachment.imageSrc = await this.utilityService.getImageUrl(attachment.url, "thumbnail");
				}
			});

			await Promise.all(imageSrcPromises);

			this.attachments = attachments;
			this.changeDetector.markForCheck();
		}
	};

	//form setup
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onChange = (_attachments: ImageAttachment[]) => {};
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onTouched = () => {};
	disabled = false;

	writeValue(attachments: ImageAttachment[]): void {
		if (attachments) {
			this.loadAttachments(attachments);
		}
	}

	registerOnChange(fn: (value: ImageAttachment[]) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}
