<app-question-template [question]="question" [isAnswered]="isAnswered">
	<ng-container *ngIf="question.variation === 'Number'; else slider">
		<ion-item>
			<ion-input
				#number
				(ionInput)="onChange(number.value)"
				[value]="model.value"
				[min]="question.minimum"
				[max]="question.maximum"
				[color]="model.isInvalidRange ? 'danger' : 'primary'"
				clearInput="true"
				inputmode="numeric"
				type="number"
				labelPlacement="stacked"
				label="{{ 'CHECKLISTS__QUESTIONS__NUMBER__PLACEHOLDER' | transloco }}"
			></ion-input>
		</ion-item>
	</ng-container>

	<ng-template #slider>
		<div class="slider-value">{{ model.value || "-" }}</div>

		<ion-item>
			<ion-range
				#slider
				(ionChange)="onChange(slider.value)"
				[value]="model.value"
				[step]="model.step"
				[min]="question.minimum"
				[max]="question.maximum"
				mode="ios"
				pin="true"
				[pinFormatter]="pinFormatter"
			></ion-range>
		</ion-item>
	</ng-template>

	<div class="invalid-input" *ngIf="model.isInvalidRange">
		<span *ngIf="question.minimum != null && question.maximum == null">
			{{ "CHECKLISTS__QUESTIONS__NUMBER__VALUE_OUT_OF_RANGE_MINIMUM" | transloco: { minimum: question.minimum } }}
		</span>
		<span *ngIf="question.minimum == null && question.maximum != null">
			{{ "CHECKLISTS__QUESTIONS__NUMBER__VALUE_OUT_OF_RANGE_MAXIMUM" | transloco: { maximum: question.maximum } }}
		</span>
		<span *ngIf="question.minimum != null && question.maximum != null">
			{{
				"CHECKLISTS__QUESTIONS__NUMBER__VALUE_OUT_OF_RANGE_BOTH"
					| transloco: { minimum: question.minimum, maximum: question.maximum }
			}}
		</span>
	</div>
</app-question-template>
