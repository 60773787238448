import { Component, OnInit } from "@angular/core";
import { AlertController, ModalController } from "@ionic/angular";
import { TranslocoService } from "@ngneat/transloco";
import { LoadingService } from "../../../configuration/services/loading.service";
import { ChecklistProgressStateQuery } from "../state/checklist-progress-state.query";
import { ChecklistProgressStateService } from "../state/checklist-progress-state.service";
import { FileUploadsModalService } from "source/app/modals/file-uploads/file-uploads-modal-service";
import { FormControl, FormGroup, FormRecord, Validators } from "@angular/forms";
import { BehaviorSubject, Observable, combineLatest, defer, map, of, startWith } from "rxjs";
import {
	Checklist,
	FormAnswerGroup,
	FormQuestion,
	ImageAttachment,
	PageElement,
	Response,
} from "../checklist.interface";
import { ChecklistService } from "source/app/features/checklists/services/checklist.service";
import { LogicProperties, LogicService } from "../services/logic.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@Component({
	selector: "app-checklist-modal",
	templateUrl: "./checklist-modal.component.html",
	styleUrls: ["./checklist-modal.component.scss"],
})
@UntilDestroy()
export class ChecklistModalComponent implements OnInit {
	public static returnCodes = {
		submitted: "Submitted",
	};

	constructor(
		private checklistProgressQuery: ChecklistProgressStateQuery,
		private checklistProgressService: ChecklistProgressStateService,
		private loadingService: LoadingService,
		private modalController: ModalController,
		private alertController: AlertController,
		private translocoService: TranslocoService,
		private FileUploadsModalService: FileUploadsModalService,
		private checklistService: ChecklistService,
		private logicService: LogicService,
	) {}

	activePage: ChecklistPageWithVisibility;
	visiblePages$: Observable<ChecklistPageWithVisibility[]>;
	activePageId$ = new BehaviorSubject("");
	isFirstPage = true;
	isLastPage = true;

	form: FormRecord<FormGroup<FormAnswerGroup>>;
	ready = false;

	cancel = async () => {
		await this.modalController.dismiss();
	};

	submit = async () => {
		//we need to know if there are any images, and if they are uploaded
		const imagesThatCantBeTranslated = await this.checklistService.imagesThatCantbeTranslated(this.form);

		if (imagesThatCantBeTranslated.length > 0) {
			const modal = await this.FileUploadsModalService.create(imagesThatCantBeTranslated);

			await modal.present();
			await modal.onWillDismiss();
			const newImagesThatCantBeTranslated = await this.checklistService.imagesThatCantbeTranslated(this.form);
			if (newImagesThatCantBeTranslated.length > 0) {
				//images stll in queu, user can click submit again later
				return;
			}
		}

		const loading = await this.loadingService.createLoader();
		loading.present();
		try {
			const checklistSubmitDelayMessage = setTimeout(() => {
				loading.message = this.translocoService.translate("CHECKLIST_SUBMIT_SUBMIT__TIME_DELAY");
			}, 7500);

			const checklist = await this.checklistProgressQuery.checklist$.firstAsync();
			const visiblePages = await this.visiblePages$.firstAsync();

			await this.checklistService.submit(checklist, this.form, visiblePages);
			clearTimeout(checklistSubmitDelayMessage);

			await this.modalController.dismiss(ChecklistModalComponent.returnCodes.submitted);
			loading.dismiss();
		} catch (_error) {
			loading.dismiss();

			const alert = await this.alertController.create({
				header: this.translocoService.translate("CHECKLIST_SUBMIT_ERROR__HEADER"),
				subHeader: this.translocoService.translate("CHECKLIST_SUBMIT_ERROR__SUB_HEADER"),
				message: this.translocoService.translate("CHECKLIST_SUBMIT_ERROR__MESSAGE"),
				buttons: [this.translocoService.translate("CHECKLIST_SUBMIT_ERROR__OK")],
			});
			await alert.present();
		}
	};

	nextPage = async () => {
		const visiblePages = await this.visiblePages$.firstAsync();
		const currentIndex = visiblePages.findIndex((page) => page.id === this.activePage.id);

		if (currentIndex < visiblePages.length - 1) {
			this.activePageId$.next(visiblePages[currentIndex + 1].id);
		}
	};

	previousPage = async () => {
		const visiblePages = await this.visiblePages$.firstAsync();
		const currentIndex = visiblePages.findIndex((page) => page.id === this.activePage.id);

		if (currentIndex > 0) {
			this.activePageId$.next(visiblePages[currentIndex - 1].id);
		}
	};

	goToPage = async (pageId: string) => {
		const visiblePages = await this.visiblePages$.firstAsync();
		const index = visiblePages.findIndex((page) => page.id === pageId);

		if (index > -1) {
			this.activePageId$.next(pageId);
		}
	};

	private createForm = async (checklist: Checklist): Promise<FormRecord<FormGroup<FormAnswerGroup>>> => {
		const checklistForm = new FormRecord<FormGroup<FormAnswerGroup>>({});

		checklist.pages.forEach((page) => {
			page.elements.forEach((element) => {
				if (element.stereotype !== "Question") {
					return;
				}

				const formGroup = this.createFormGroup();
				if (page.logic != null || element.logic != null) {
					//If Logic is defined, the question is not available, until logic triggers the question
					formGroup.disable();
				}

				checklistForm.addControl(element.id, formGroup);
			});
		});

		return checklistForm;
	};

	private createFormGroup = (): FormGroup<FormAnswerGroup> => {
		const questionFormControl = new FormControl<FormQuestion>(
			{
				disabled: false,
				value: undefined,
			},
			[Validators.required],
		);

		const commentFormControl = new FormControl<string>({ value: "", disabled: false });
		const attachmentFormControl = new FormControl<ImageAttachment[]>({ value: [], disabled: false });

		const group = new FormGroup<FormAnswerGroup>({
			question: questionFormControl,
			comment: commentFormControl,
			attachments: attachmentFormControl,
		});

		return group;
	};

	private getFormValue = (resultValues: Response[]): Record<string, FormResponse> => {
		const record: Record<string, FormResponse> = {};

		resultValues.forEach((response) => {
			const {
				answers,
				answered,
				position,
				user,
				question: key,
				comment,
				imageAttachments: attachments,
			} = response;

			const question: FormQuestion = {
				answers,
				answered,
				position,
				user,
			};

			record[key] = {
				question,
				comment,
				attachments,
			};
		});

		return record;
	};

	private initializeChecklistLogic = (checklist: Checklist, logicProperties: LogicProperties) => {
		const elementsVisible: { id: string; isVisible$: Observable<boolean> }[] = [];
		const pages: ChecklistPageWithVisibility[] = checklist.pages.map((page) => {
			const isPageVisible$: Observable<boolean> = this.logicService.evaluatePageLogic(
				page,
				logicProperties,
				elementsVisible,
				this.form,
			);

			const elements: CheklistPageElementWithVisibility[] = page.elements.map((element) => {
				const isElementVisible$: Observable<boolean> = this.logicService.evaluateElementLogic(
					isPageVisible$,
					element,
					logicProperties,
					elementsVisible,
					this.form,
				);

				elementsVisible.push({ id: element.id, isVisible$: isElementVisible$ });

				isElementVisible$.pipe(untilDestroyed(this)).subscribe();

				return {
					isVisible$: combineLatest([isPageVisible$, isElementVisible$]).pipe(
						map(([isPageVisible, isElementVisible]) => isPageVisible && isElementVisible),
					),
					element: element,
				};
			});

			const questionElementIds = page.elements
				.filter((element) => element.stereotype == "Question")
				.map((element) => element.id);

			const questions = questionElementIds.map(
				(elementId) => <Observable<boolean>>defer(() =>
						this.form.get(elementId).statusChanges.pipe(
							startWith(this.form.get(elementId).status),
							map((status) => status === "VALID" || status === "DISABLED"),
						),
					),
			);

			const isCompleted$ =
				questionElementIds.length == 0
					? of(true)
					: combineLatest(questions).pipe(map((values) => values.every((value) => value)));

			return {
				...page,
				isCompleted$,
				isVisible$: isPageVisible$,
				elements,
			};
		});

		this.visiblePages$ = combineLatest(
			pages.map((page) => page.isVisible$.pipe(map((visible) => ({ page, visible })))),
		).pipe(map((results) => results.filter((result) => result.visible).map((result) => result.page)));
	};

	private saveFormOnChanges = () => {
		this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((values) => {
			const responses: Response[] = Object.entries(values)
				.filter(([key, _value]) => this.form.controls[key].valid && this.form.controls[key].enabled)
				.map(([key, value]) => ({
					answers: value.question.answers,
					answered: value.question.answered,
					position: value.question.position,
					user: value.question.user,
					question: key,
					comment: value.comment,
					imageAttachments: value.attachments,
				}));

			this.checklistProgressService.setResponses(responses);
		});
	};

	async ngOnInit(): Promise<void> {
		const checklist = await this.checklistProgressQuery.checklist$.firstAsync();
		const resultValues = await this.checklistProgressQuery.responses$.firstAsync();
		const logicProperties = await this.logicService.getLogicProperties(checklist);

		this.form = await this.createForm(checklist);
		const formValue = this.getFormValue(resultValues);
		this.form.patchValue(formValue);

		this.initializeChecklistLogic(checklist, logicProperties);
		this.saveFormOnChanges();

		combineLatest([this.activePageId$, this.visiblePages$]).subscribe(([activePageId, visiblePages]) => {
			const currentPageId = activePageId || visiblePages[0].id;
			this.activePage = visiblePages.find((page) => page.id === currentPageId);
			this.isFirstPage = visiblePages[0].id === currentPageId;
			this.isLastPage = visiblePages[visiblePages.length - 1].id === currentPageId;
		});

		this.ready = true;
	}
}

export type FormResponse = Partial<{
	question: FormQuestion;
	comment: string;
	attachments: ImageAttachment[];
}>;

export interface ChecklistPageWithVisibility {
	id: string;
	name: string;
	isVisible$: Observable<boolean>;
	isCompleted$: Observable<boolean>;
	elements: CheklistPageElementWithVisibility[];
}

export interface CheklistPageElementWithVisibility {
	isVisible$: Observable<boolean>;
	element: PageElement;
}
