<app-question-template [question]="question" [isAnswered]="isAnswered" *ngIf="dateTimeSettings$ | async; let settings">
	<ion-datetime
		(ionChange)="onChange($event)"
		[firstDayOfWeek]="settings.firstDayOfWeek"
		[hourCycle]="hourCycle$ | async"
		[presentation]="presentation"
		[value]="answer?.answers[0]"
		size="cover"
	></ion-datetime>
</app-question-template>
