import { Injectable } from "@angular/core";
import { ChecklistStateStore } from "./checklist-state.store";

@Injectable({ providedIn: "root" })
export class ChecklistStateService {
	constructor(private checklistStore: ChecklistStateStore) {}

	setPreopeningChecklistValidity = (validUntil: Date | string) => {
		if (typeof validUntil == "object") {
			validUntil = validUntil?.toUTCString();
		}

		this.checklistStore.update({
			preopeningChecklistValidity: validUntil,
		});
	};
}
