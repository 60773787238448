<ion-textarea
	(ionInput)="onCommentChange($event)"
	[autoGrow]="true"
	[value]="commentModel"
	fill="outline"
	label="{{ 'CHECKLISTS__COMMENT_PLACEHOLDER' | transloco }}"
	labelPlacement="floating"
	debounce="1000"
	*ngIf="showComment"
></ion-textarea>
