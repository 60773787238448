import { Injectable } from "@angular/core";
import { combineQueries, Query } from "@datorama/akita";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { LocationState, LocationStateStore } from "./location-state.store";
import { UiQuery } from "./ui.query";

@Injectable({ providedIn: "root" })
export class LocationStateQuery extends Query<LocationState> {
	constructor(
		protected override store: LocationStateStore,
		private uiQuery: UiQuery,
	) {
		super(store);
	}

	selectState$ = this.select([(state) => state.state, (state) => state.downtime]).pipe(
		map(([state, downtime]): "Open" | "Closed" | "Down" => {
			return downtime != null ? "Down" : state;
		}),
	);

	preopeningChecklist$ = this.select((state) => state.preopeningChecklist);
	closingChecklist$ = this.select((state) => state.closingChecklist);
	createdDowntimesAreBlocking$ = this.select((state) => state.createdDowntimesAreBlocking);

	selectOperator$ = this.select((state) => state.operator);
	operatorDateTimeSettings$ = this.selectOperator$.pipe(map((operator) => operator.dateTimeSettings));

	locationIsOpen$ = this.select((state) => state.state == "Open");
	rideOpsKey$ = this.select((state) => state.apiKey);
	rideOpsKeyAndOperator$ = this.select([(state) => state.apiKey, (state) => state.operator]);

	location$ = this.select((state) => state.location);
	locationProperties$ = this.select((state) => state.locationProperties);
	locationName$ = this.select((state) => state.location?.name);
	isClearedForOperation$ = this.select(
		(state) => state.isClearedForOperationDetails.isClearedForOperation || state.isClearedForOperation,
	);
	isClearedForOperationDetails$ = this.select((state) => state.isClearedForOperationDetails);
	messageForOperatorWhenNotReadyForOperation$ = this.select(
		(state) => state.rideOpsSettings.messageForOperatorWhenNotReadyForOperation,
	);
	messageForOperatorWhenNoCriticalForOperationChecklistsPlanned$ = this.select(
		(state) => state.rideOpsSettings.messageForOperatorWhenNoCriticalForOperationChecklistsPlanned,
	);

	mandatoryPositionsWithoutAttendant$ = this.select((state) => state.rideOpsSettings.positions).pipe(
		map((positions) => positions?.filter((position) => position.mandatory && position.attendant == null) || []),
	);

	isSafeToOperate$ = combineLatest([
		this.selectState$.pipe(map((state) => state === "Down")),
		this.isClearedForOperation$,
		this.mandatoryPositionsWithoutAttendant$,
	]).pipe(
		map(
			([isDown, isClearedForOperation, mandatoryPositionsWithoutAttendant]) =>
				isDown === false && isClearedForOperation === true && mandatoryPositionsWithoutAttendant.length === 0,
		),
	);

	operatorIsSelected$ = this.select((state) => !!state.operator);
	rideOpsKeyIsSet$ = this.select((state) => !!state.apiKey);
	locationIsSet$ = this.select((state) => !!state.location);
	canLogout$ = this.select((state) => !!state.operator && state.state != "Open");
	operators$ = this.select((state) => state.operators);
	downtime$ = this.select((state) => state.downtime);

	requiredCompetencies$ = this.select((state) => state.requiredCompetencies);
	requiredCompetenciesForAttendants$ = this.select((state) => state.requiredCompetenciesForAttendants);

	noteTypes$ = this.select((state) => state.noteTypes);
	noteTemplates$ = this.select((state) => state.noteTemplates);
	downtimeTemplates$ = this.select((state) => state.downtimeTemplates);

	lastQueueTimeUpdate$ = this.select((state) => state.lastQueueUpdate);
	lastRidersUpdate$ = this.select((state) => state.lastRidersUpdate);

	lastServerUpdate$ = this.select((state) => state.lastServerUpdate);
	lastUpdateFailed$ = this.select((state) => state.lastUpdateFailed);

	riders$ = this.select((state) => state.riders);
	dispatches$ = this.select((state) => state.dispatches);

	operator$ = this.select((state) => state.operator);
	attendants$ = this.select((state) => state.rideOpsSettings.attendants);
	locationAndOperator$ = this.select([(state) => state.location, (state) => state.operator]);

	positions$ = this.select((state) => state.rideOpsSettings.positions || []);

	operatorLocationOperationalSettings$ = this.select([
		(state) => state.operator,
		(state) => state.location,
		(state) => state.rideOpsSettings,
	]);

	allowStatisticsForOperator$ = this.select((state) => state.rideOpsSettings.allowStatisticsForOperator);

	languageAndOperator$ = this.select([(state) => state.language, (state) => state.operator]);

	rideOpsSettings$ = this.select((state) => state.rideOpsSettings);

	opsLocOpsSettingsCartsnSeats$ = combineQueries([
		this.operatorLocationOperationalSettings$,
		this.uiQuery.cartsInUse$,
		this.uiQuery.seatsUnavailable$,
	]);

	dispatchUpdateWarningThreshold$ = this.select((state) => state.rideOpsSettings.dispatchUpdateWarningThreshold);

	minumumSecondsBetweenDispatches$ = this.select((state) => state.rideOpsSettings.minumumSecondsBetweenDispatches);
	maximumCapacityPerDispatch$ = combineLatest([
		this.rideOpsSettings$,
		this.uiQuery.cartsInUse$,
		this.uiQuery.seatsUnavailable$,
	]).pipe(
		map(([settings, dispatchUnitsAvailable, seatsUnavailable]) => {
			let capacityPerDispatch = settings.dispatchUnitCapacity;

			if (dispatchUnitsAvailable === 1) {
				capacityPerDispatch -= seatsUnavailable;
			}

			if (capacityPerDispatch < 0) {
				capacityPerDispatch = 0;
			}

			return capacityPerDispatch;
		}),
	);
}
