import { Component, Input } from "@angular/core";
import { ServiceLocator } from "../../../../app.module";
import { FormQuestion, Question } from "../../checklist.interface";
import { ChecklistProgressStateQuery } from "../../state/checklist-progress-state.query";
import { ChecklistProgressStateService } from "../../state/checklist-progress-state.service";
import { ControlValueAccessor } from "@angular/forms";
import { PositionService } from "source/app/configuration/services/position.service";
import { LocationStateQuery } from "source/app/configuration/state/location-state.query";

@Component({ template: "" })
export class QuestionComponent<T extends Question> implements ControlValueAccessor {
	protected checklistProgressService = ServiceLocator.get(ChecklistProgressStateService);
	protected checklistProgressQuery = ServiceLocator.get(ChecklistProgressStateQuery);
	protected positionService = ServiceLocator.get(PositionService);
	protected locationStateQuery = ServiceLocator.get(LocationStateQuery);

	@Input() question: T;

	public answer: FormQuestion | undefined;
	public isAnswered: boolean;

	private updateIsAnswered = () => {
		this.isAnswered = this.answer?.answers.length > 0;
	};

	protected setAnswers = async (...answers: string[]): Promise<void> => {
		answers = (answers || []).filter((answer) => answer?.length > 0);

		if (answers.length === 0) {
			answers = undefined;
			this.answer = undefined;
			this.onFormChange(null);
		} else {
			const operator = await this.locationStateQuery.selectOperator$.firstAsync();

			const formAnswer: FormQuestion = {
				answers: answers,
				answered: new Date().toISOString(),
				position: this.positionService.getCurrentPosition(),
				user: operator.id,
			};
			this.answer = formAnswer;
			this.onFormChange(formAnswer);
		}

		this.updateIsAnswered();
	};

	//Form Setup
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onFormChange = (_answer: FormQuestion) => {};
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onTouched = () => {};

	disabled = false;

	registerOnChange(fn: (value: FormQuestion) => void): void {
		this.onFormChange = fn;
	}
	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	writeValue(answer: FormQuestion | undefined): void {
		if (answer != undefined) {
			this.answer = answer;
		}

		this.updateIsAnswered();
	}
}
