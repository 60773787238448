<app-question-template [question]="question" [isAnswered]="isAnswered">
	<ion-item>
		<ion-textarea
			(ionInput)="onChange($event)"
			autoGrow="true"
			type="text"
			labelPlacement="stacked"
			label="{{ 'CHECKLISTS__QUESTIONS__TEXT__PLACEHOLDER' | transloco }}"
			#questionText
			required
		></ion-textarea>
	</ion-item>
</app-question-template>
