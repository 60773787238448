import { ChangeDetectionStrategy, Component, forwardRef } from "@angular/core";
import { QuestionComponent } from "../question.component";
import { ToggleCustomEvent } from "@ionic/angular";
import { UnsupportedQuestionsService } from "../../../services/unsupported-questions.service";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
	selector: "app-uknown-question",
	templateUrl: "./unknown-question.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => UnknownQuestionComponent),
			multi: true,
		},
	],
})
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class UnknownQuestionComponent extends QuestionComponent<any> implements ControlValueAccessor {
	toggleAccept = (event: ToggleCustomEvent) => {
		this.onTouched();
		this.setAnswers(event.detail.checked ? UnsupportedQuestionsService.unsupportedAnswerValue : null);
	};
}
