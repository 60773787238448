<app-question-template [question]="question" [isAnswered]="isAnswered">
	<app-file-upload [file$]="signatureFile$" [tags]="tags" (fileId)="setAnswers($event)">
		<ion-img *ngIf="signatureImage" [src]="signatureImage"></ion-img>
	</app-file-upload>

	<div class="button-container">
		<ion-button shape="round" color="primary" (click)="openSignModal()">
			<ion-icon slot="end" name="pencil-outline" />
			{{ "SIGNATURE_QUESTION__SIGN" | transloco }}
		</ion-button>
	</div>
</app-question-template>
