import { Injectable } from "@angular/core";
import { Query } from "@datorama/akita";
import { map, shareReplay, startWith } from "rxjs/operators";
import { ChecklistState, ChecklistStateStore } from "./checklist-state.store";
import { combineLatest, interval } from "rxjs";

@Injectable({ providedIn: "root" })
export class ChecklistStateQuery extends Query<ChecklistState> {
	constructor(protected override store: ChecklistStateStore) {
		super(store);
	}

	private everyMinute$ = interval(60 * 1000);

	openingChecklistValidity$ = combineLatest([
		this.everyMinute$.pipe(startWith(0)),
		this.select((state) => state.preopeningChecklistValidity),
	]).pipe(
		map(([_ticks, validity]) => {
			if (validity == null) {
				return null;
			}

			const now = new Date();
			const validUntil = new Date(validity);

			return validUntil > now ? validUntil : null;
		}),
		shareReplay({ bufferSize: 1, refCount: true }),
	);
}
