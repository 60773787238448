import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Observable, combineLatest, distinctUntilChanged, map, of, shareReplay, tap } from "rxjs";
import { Checklist, LogicalOperator, Page, PageElement } from "../checklist.interface";
import { LocationStateQuery } from "source/app/configuration/state/location-state.query";
import { ApiService } from "source/app/configuration/services/api.service";
import { RaygunService } from "source/app/configuration/services/raygun.service";
import { throwExhaustiveError } from "source/app/configuration/errors/exhaustive-error";
import {
	evaluatePropertyCondition,
	evaluateDateTimeCondition,
	evaluateStateCondition,
	evaluateMembershipCondition,
	evaluateVisibilityCondition,
	evaluateAnswerCondition,
} from "./logic";

@Injectable({ providedIn: "root" })
export class LogicService {
	constructor(
		private locationStateQuery: LocationStateQuery,
		private apiService: ApiService,
		private raygunService: RaygunService,
	) {}

	/**
	 * Evaluates the logic for displaying a page based on the provided page object, logic properties, visibility of elements, and form group.
	 *
	 * @param page The page object containing description, elements, and logic operator.
	 * @param logicProperties The logic properties including location properties, state, and memberships.
	 * @param elementsVisible An array of objects with element IDs and their visibility status as observables.
	 * @param formGroup The form group related to the page.
	 * @returns An observable emitting a boolean value indicating whether the page should be displayed.
	 */
	public evaluatePageLogic(
		page: Page,
		logicProperties: LogicProperties,
		elementsVisible: { id: string; isVisible$: Observable<boolean> }[],
		formGroup: FormGroup,
	): Observable<boolean> {
		// Always show the page if no logic is defined
		if (page.logic == undefined) {
			return of(true);
		}

		return this.evaluateLogicalConditions(page.logic, logicProperties, elementsVisible, formGroup).pipe(
			distinctUntilChanged(),
			shareReplay(1),
		);
	}

	/**
	 * Evaluates the logic for a given page element to determine its visibility based on the provided logical conditions.
	 *
	 * @param isPageVisible$ An Observable<boolean> representing the visibility of the page.
	 * @param element The PageElement to evaluate the logic for.
	 * @param logicProperties The LogicProperties object containing the logic properties for evaluation.
	 * @param elementsVisible An array of objects containing the id and Observable<boolean> visibility status of elements.
	 * @param formGroup The FormGroup to update based on the visibility of the element.
	 * @returns An Observable<boolean> representing the combined visibility of the element and the page.
	 */
	public evaluateElementLogic(
		isPageVisible$: Observable<boolean>,
		element: PageElement,
		logicProperties: LogicProperties,
		elementsVisible: { id: string; isVisible$: Observable<boolean> }[],
		formGroup: FormGroup,
	): Observable<boolean> {
		const isElementVisible$ =
			element.logic == undefined
				? of(true)
				: this.evaluateLogicalConditions(element.logic, logicProperties, elementsVisible, formGroup);

		let isElementAndPageVisible$ = combineLatest([isPageVisible$, isElementVisible$]).pipe(
			map(([isPageVisible, isElementVisible]) => isPageVisible && isElementVisible),
		);

		// If it's a question, we need to check if it's visible and update the form control accordingly
		if (element.stereotype === "Question") {
			isElementAndPageVisible$ = isElementAndPageVisible$.pipe(
				distinctUntilChanged(),
				tap((isVisible) => {
					const control = formGroup.controls[element.id];
					isVisible ? control.enable() : control.disable();
				}),
				shareReplay(1),
			);
		}

		return isElementAndPageVisible$;
	}

	/**
	 * Evaluates logical conditions based on the provided logic operator, logic properties, visible elements, and form group.
	 * Returns an observable boolean representing the evaluation result.
	 *
	 * @param logic The logical operator containing conditions to evaluate
	 * @param logicProperties The logic properties used in the evaluation
	 * @param elementsVisible Array of elements with visibility status observables
	 * @param formGroup The form group used for condition evaluation
	 * @returns An observable boolean representing the result of the logical conditions evaluation
	 */
	private evaluateLogicalConditions(
		logic: LogicalOperator,
		logicProperties: LogicProperties,
		elementsVisible: { id: string; isVisible$: Observable<boolean> }[],
		formGroup: FormGroup,
	): Observable<boolean> {
		const logicObserverables: Observable<boolean>[] = logic.conditions.map((condition) => {
			try {
				switch (condition.type) {
					case "Answer":
						return evaluateAnswerCondition(condition, formGroup);
					case "DateTime":
						return evaluateDateTimeCondition(condition);
					case "Property":
						return evaluatePropertyCondition(condition, logicProperties.locationProperties);
					case "State":
						return evaluateStateCondition(condition, logicProperties.state);
					case "Membership":
						return evaluateMembershipCondition(condition, logicProperties.memberships);
					case "Visibility":
						return evaluateVisibilityCondition(condition, elementsVisible);
					default: {
						throwExhaustiveError("Unhandled logical condition", condition);
					}
				}
			} catch (error) {
				if (error instanceof Error) {
					this.raygunService.send(error, condition);
				}

				return of(true);
			}
		});

		return combineLatest(logicObserverables).pipe(
			map((values: boolean[]) => {
				switch (logic.type) {
					case "AND":
						return values.every((value) => value);
					case "OR":
						return values.indexOf(true) !== -1;
					case "NOT":
						return values.every((value) => value === false);
					default:
						return true;
				}
			}),
			distinctUntilChanged(),
		);
	}

	/**
	 * Retrieves logic properties for a checklist including location properties, state, and memberships.
	 * @param checklist The checklist for which logic properties are retrieved.
	 * @returns A Promise that resolves to an object containing locationProperties, state, and memberships.
	 */
	public async getLogicProperties(checklist: Checklist): Promise<LogicProperties> {
		const locationProperties = await this.locationStateQuery.locationProperties$.firstAsync();
		const state = "";
		let memberships: string[] = [];

		// Get logical conditions related to memberships from pages
		const pageLogicalConditionsWithMembership = checklist.pages
			.filter((x) => x.logic != undefined)
			.flatMap((page) => page.logic)
			.flatMap((x) => x.conditions)
			.find((x) => x.type === "Membership");

		// Get logical conditions related to memberships from elements
		const elementLogicalConditionsWithMembership = checklist.pages
			.flatMap((page) => page.elements)
			.filter((x) => x.logic != undefined)
			.map((element) => element.logic)
			.flatMap((x) => x.conditions)
			.find((x) => x.type === "Membership");

		const hasMembershipConditions =
			pageLogicalConditionsWithMembership != undefined || elementLogicalConditionsWithMembership != undefined;

		if (hasMembershipConditions) {
			memberships = await this.apiService.memberships();
		}

		return {
			locationProperties,
			state,
			memberships,
		};
	}
}

export interface LogicProperties {
	locationProperties: string[];
	state: string;
	memberships: string[];
}
