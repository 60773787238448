import { Injectable } from "@angular/core";
import { FormGroup, FormRecord } from "@angular/forms";
import { Checklist, Result, ResultValue, Response, ImageAttachment, FormAnswerGroup } from "../checklist.interface";
import { ChecklistPageWithVisibility } from "../modals/checklist-modal.component";
import { firstValueFrom, map, timeout } from "rxjs";
import { LocationStateQuery } from "source/app/configuration/state/location-state.query";
import { HttpClient } from "@angular/common/http";
import { EnvironmentQuery } from "source/app/configuration/state/environment.query";
import { ChecklistStateService } from "../state/checklist-state.service";
import { validate as uuidValidate } from "uuid";
import { FileUploadsQuery } from "source/app/configuration/state/file-uploads.query";
import { FileTranslation } from "source/app/configuration/state/file-translation.model";
import { FileTranslationsQuery } from "source/app/configuration/state/file-translations.query";

@Injectable({ providedIn: "root" })
export class ChecklistService {
	constructor(
		private http: HttpClient,
		private environmentQuery: EnvironmentQuery,
		private locationStateQuery: LocationStateQuery,
		private checklistStateService: ChecklistStateService,
		private fileTranslationsQuery: FileTranslationsQuery,
		private fileUploadsQuery: FileUploadsQuery,
	) {}

	submit = async (
		checklist: Checklist,
		checklistForm: FormRecord<FormGroup<FormAnswerGroup>>,
		visiblePages: ChecklistPageWithVisibility[],
	) => {
		if (checklistForm.valid) {
			const result = await this.convertToResult(checklist, checklistForm, visiblePages);
			const environment = await this.environmentQuery.environment$.firstAsync();

			await this.http.post(`${environment.api.url}api/rideops/result`, result).pipe(timeout(60000)).firstAsync();

			if (checklist.activity === "Preopening") {
				this.checklistStateService.setPreopeningChecklistValidity(new Date().addHours(12));
			}
		}
	};

	private convertToResult = async (
		checklist: Checklist,
		checklistForm: FormRecord<FormGroup<FormAnswerGroup>>,
		pages: ChecklistPageWithVisibility[],
	): Promise<Result> => {
		let responses = this.getValuesFromForm(checklistForm);
		responses = await this.translateImages(responses);

		const pageVisibility = pages.map((page) => {
			return firstValueFrom(
				page.isVisible$.pipe(
					map((visible) => {
						return {
							visible: visible,
							id: page.id,
						};
					}),
				),
			);
		});

		const visiblePages = (await Promise.all(pageVisibility)).filter((x) => x.visible).map((x) => x.id);

		const elementVisibility = pages
			.flatMap((page) => page.elements)
			.map((element) => {
				return firstValueFrom(
					element.isVisible$.pipe(
						map((visible) => {
							return {
								visible$: visible,
								id: element.element.id,
							};
						}),
					),
				);
			});

		const visbleElements = (await Promise.all(elementVisibility)).filter((x) => x.visible$).map((x) => x.id);
		const location = await this.locationStateQuery.location$.firstAsync();

		return <Result>{
			answered: new Date().toISOString(),
			checklist: checklist.id,
			language: checklist.language,
			location: location.id,
			schedule: location.id,
			started: checklist.started,
			timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
			values: responses,

			visiblePages: visiblePages,
			visibleElements: visbleElements,

			metadata: {
				version: "RideOps",
				storageDriver: "N/A",
				deployVersion: "N/A",
			},
		};
	};

	public imagesThatCantbeTranslated = async (
		checklistForm: FormRecord<FormGroup<FormAnswerGroup>>,
	): Promise<string[]> => {
		const responses = this.getValuesFromForm(checklistForm);
		const imagesNotTranslated = this.imagesNotTranslated(responses);

		if (imagesNotTranslated.length === 0) {
			return [];
		}
		return this.fileUploadsQuery
			.selectMany(imagesNotTranslated)
			.pipe(map((fileUploads) => fileUploads.map((fu) => fu.id)))
			.firstAsync();
	};

	private translateImages = async (responses: Response[]): Promise<Response[]> => {
		const imagesNotTranslated = this.imagesNotTranslated(responses);
		if (imagesNotTranslated.length === 0) {
			return responses;
		}

		const translations = await this.fileTranslationsQuery.selectMany(imagesNotTranslated).firstAsync();

		const mapImageAttachment = (
			imageAttachment: ImageAttachment,
			translations: FileTranslation[],
		): ImageAttachment => {
			if (uuidValidate(imageAttachment.url)) {
				return {
					url: translations.find((translation) => translation.id === imageAttachment.url).path,
					cameraRoll: imageAttachment.cameraRoll,
				};
			}
			return imageAttachment;
		};

		const translatedResponses = responses.map((response) =>
			uuidValidate(response.answers[0]) ||
			(response.imageAttachments != null && response.imageAttachments.length > 0)
				? {
						...response,
						answers: uuidValidate(response.answers[0])
							? [translations.find((translation) => translation.id === response.answers[0]).path]
							: response.answers,
						imageAttachments:
							response.imageAttachments == null || response.imageAttachments.length == 0
								? []
								: response.imageAttachments.map((image) => mapImageAttachment(image, translations)),
					}
				: response,
		);

		return translatedResponses;
	};

	private getValuesFromForm = (checklistForm: FormRecord<FormGroup<FormAnswerGroup>>) => {
		const values: ResultValue[] = Object.entries(checklistForm.value)
			.filter(([key, _value]) => checklistForm.controls[key].valid && checklistForm.controls[key].enabled) //we only want the valid answers
			.map(([key, value]) => {
				const retval: ResultValue = {
					answers: value.question.answers,
					answered: value.question.answered,
					position: value.question.position,
					user: value.question.user,
					question: key,
					comment: value.comment,
					imageAttachments: value.attachments,
				};
				return retval;
			});

		return values;
	};

	private imagesNotTranslated = (responses: Response[]): string[] => {
		return responses
			.map((response) =>
				response.imageAttachments == undefined
					? [response.answers[0]]
					: [response.answers[0], ...response.imageAttachments.map((ia) => ia.url)],
			)
			.flatMap((x) => x)
			.filter((x) => uuidValidate(x));
	};
}
