import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Question } from "../../checklist.interface";
import { ChecklistProgressStateQuery } from "../../state/checklist-progress-state.query";

import { CommentComponent } from "../shared/comment/comment.component";
import { AttachmentsComponent } from "../shared/attachments/attachments.component";
import { ControlContainer, FormGroupDirective } from "@angular/forms";

@Component({
	selector: "app-question-template",
	templateUrl: "./question-template.component.html",
	styleUrls: ["./question-template.component.scss"],
	viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class QuestionTemplateComponent implements OnInit {
	constructor(private checklistProgressQuery: ChecklistProgressStateQuery) {}

	@Input() question: Question;
	@Input() isAnswered: boolean;

	@ViewChild(CommentComponent, { static: false })
	private commentComponent: CommentComponent;

	@ViewChild(AttachmentsComponent, { static: false })
	private attachmentsComponent: AttachmentsComponent;

	activateComment = () => {
		this.commentComponent.activateComment();
	};

	getImage = () => {
		this.attachmentsComponent.getImage();
	};

	getFromGallery = () => {
		this.attachmentsComponent.getFromGallery();
	};

	showMoreButton = false;

	ngOnInit(): void {
		this.showMoreButton =
			this.question.allowCameraRoll || this.question.allowComments || this.question.allowImageAttachment;
	}
}
