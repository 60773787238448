<div class="attachments" *ngIf="attachments != null && attachments.length > 0">
	<div class="headline">{{ "ATTACHMENTS__HEADLINE" | transloco }}</div>

	<div class="thumbnails">
		<ion-thumbnail *ngFor="let attachment of attachments" (click)="edit(attachment)">
			<img [src]="attachment.imageSrc" alt="image thumbnail" />
		</ion-thumbnail>

		<ion-thumbnail (click)="add()">
			<ion-icon class="full" name="add-circle-outline"></ion-icon>
		</ion-thumbnail>
	</div>
</div>
