import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { applyTransaction, guid } from "@datorama/akita";
import { AlertController, NavController } from "@ionic/angular";
import { TranslocoService } from "@ngneat/transloco";
import { retryBackoff } from "backoff-rxjs";
import { combineLatest, from } from "rxjs";
import { filter, switchMap } from "rxjs/operators";
import { ChecklistProgressStateStore } from "../../features/checklists/state/checklist-progress-state.store";
import { ApiService } from "../services/api.service";
import { IdleService } from "../services/idle.service";
import { ActionsStore } from "./actions.store";
import { EnvironmentQuery } from "./environment.query";
import { LocationStateQuery } from "./location-state.query";
import {
	Downtime,
	IsClearedForOperationDetails,
	LastQueueTimeUpdate,
	LastRidersUpdate,
	Location,
	LocationState,
	LocationStateStore,
	NoteTemplateReference,
	NoteTypeReference,
	RideOpsSettings,
	RidePosition,
} from "./location-state.store";
import { UiService } from "./ui.service";
import { UiStore } from "./ui.store";
import { LanguageService } from "../services/language.service";
import { arrayUpdate } from "@datorama/akita";

@Injectable({ providedIn: "root" })
export class LocationStateService {
	constructor(
		private locationStateStore: LocationStateStore,
		private locationStateQuery: LocationStateQuery,
		private navController: NavController,
		private apiService: ApiService,
		private alertController: AlertController,
		private router: Router,
		private translocoService: TranslocoService,
		private environmentQuery: EnvironmentQuery,
		private UiService: UiService,
		private uiStore: UiStore,
		private actionsStore: ActionsStore,
		private checklistProgressStore: ChecklistProgressStateStore,
		private http: HttpClient,
		private idleService: IdleService,
		private languageService: LanguageService,
	) {
		//To Make sure we dont have an invalied cache after startup, we expire it on boot
		//Since we do this always, we no longer listen for browser refreshes
		this.locationStateStore.setHasCache(false);
		this.listenForLanguageChanges();
	}
	private isActivated = false;

	showWarningNotClearedForOperation = async () => {
		const notReadyAlert = await this.alertController.create({
			header: this.translocoService.translate("NOT_READY_WARNING_ON_OPEN_ALERT__TITLE"),
			message: this.translocoService.translate("NOT_READY_WARNING_ON_OPEN_ALERT__MESSAGE"),
			buttons: [
				{
					text: this.translocoService.translate("NOT_READY_WARNING_ON_OPEN_ALERT__CANCEL"),
					role: "cancel",
				},
			],
		});

		await notReadyAlert.present();
	};
	openLocation = async () => {
		try {
			await this.apiService.open();
			this.locationStateStore.update({ state: "Open", downtime: null });
			await this.navController.navigateRoot("dashboard");
		} catch {
			await this.showErrorWhileOpeningLocationAlert();
		}
	};

	closeLocation = async (dispatches: number, riders: number) => {
		try {
			await this.apiService.close(dispatches, riders);
			this.locationStateStore.update({ state: "Closed", riders: 0, dispatches: 0 });
			this.removeAllAttendantsOnPositions();
		} catch {
			await this.showErrorWhileClosingLocationAlert();
		}
	};

	setLocationAsdown = async (description: string) => {
		const isBlocking = await this.locationStateQuery.createdDowntimesAreBlocking$.firstAsync();

		const placeholderDowntime: Downtime = {
			description: description,
			updated: new Date().toISOString(),
			state: isBlocking ? "Blocking" : "Open",
			id: guid(),
		};

		this.locationStateStore.update({ downtime: placeholderDowntime, state: "Closed" });
		this.removeAllAttendantsOnPositions();
	};

	setRideOpsKey = (key: string): void => {
		if (key?.length <= 0) {
			console.warn("Attempted to set rideops key that was null or empty.");
			return;
		}

		return this.locationStateStore.update({ apiKey: key });
	};

	setLocation = (location: Location) => {
		applyTransaction(() => {
			this.locationStateStore.update({ location: location });
			this.locationStateStore.setHasCache(false);
		});
	};

	setCurrentOperator = (operator: Operator): void => {
		const loggedIn = new Date().toISOString();
		return this.locationStateStore.update({ operator: { ...operator, loggedIn } });
	};

	setAttendantOnPosition = (attendant: Attendant, position: RidePosition) => {
		const newRidePosition: RidePosition = {
			...position,
			attendant: {
				user: {
					id: attendant.id,
					name: attendant.name,
					profileImage: attendant.profileImage,
				},
				loggedIn: new Date().toISOString(),
			},
		};

		return this.locationStateStore.update((state) => ({
			rideOpsSettings: {
				...state.rideOpsSettings,
				positions: arrayUpdate(
					state.rideOpsSettings.positions,
					(x) => {
						return x.identifier === position.identifier;
					},
					newRidePosition,
				),
			},
		}));
	};

	removeAttendantOnPosition = (position: RidePosition) => {
		const newRidePosition: RidePosition = {
			...position,
			attendant: null,
		};

		return this.locationStateStore.update((state) => ({
			rideOpsSettings: {
				...state.rideOpsSettings,
				positions: arrayUpdate(
					state.rideOpsSettings.positions,
					(x) => {
						return x.identifier === position.identifier;
					},
					newRidePosition,
				),
			},
		}));
	};

	removeAllAttendantsOnPositions = () => {
		return this.locationStateStore.update((state) => ({
			rideOpsSettings: {
				...state.rideOpsSettings,
				positions: state.rideOpsSettings.positions.map((posistion) => {
					return { ...posistion, attendant: undefined };
				}),
			},
		}));
	};

	setPinForOperator = (pin: string): void => {
		return this.locationStateStore.update((state) => {
			return {
				operator: { ...state.operator, pin: pin, pinCodeUsed: true },
			};
		});
	};
	clearOperator = async (skipNavigate = false) => {
		this.locationStateStore.update({ operator: undefined });
		this.removeAllAttendantsOnPositions();

		if (skipNavigate === true) {
			return false;
		}

		return await this.navController.navigateRoot("operators");
	};
	updateDispatchesAndRiders = (lastUpdated: Date, riders: number, dispatches = 1): void => {
		return this.locationStateStore.update((state) => ({
			lastRidersUpdate: {
				lastUpdated: lastUpdated,
				riders: riders,
			},
			riders: state.riders + riders,
			dispatches: state.dispatches + dispatches,
		}));
	};
	updateQueueTime = (lastUpdated: Date, queueTime: number): void => {
		return this.locationStateStore.update({
			lastQueueUpdate: {
				lastUpdated: lastUpdated,
				time: queueTime,
			},
		});
	};
	setRideOpsState = (
		lastQueueTimeUpdate: LastQueueTimeUpdate,
		lastRidersUpdate: LastRidersUpdate,
		riders: number,
		dispatches: number,
	): void => {
		return this.locationStateStore.update({
			lastQueueUpdate: lastQueueTimeUpdate,
			lastRidersUpdate: lastRidersUpdate,
			riders: riders,
			dispatches: dispatches,
		});
	};
	resetRideOpsState = (): void => {
		return this.locationStateStore.update({
			lastQueueUpdate: undefined,
			lastRidersUpdate: undefined,
			riders: 0,
			dispatches: 0,
		});
	};
	resetApplication = () => {
		this.checklistProgressStore.reset();
		this.locationStateStore.reset();
		this.actionsStore.reset();
		this.uiStore.reset();

		return this.navController.navigateRoot("setup");
	};
	private alertNoLongerClearedForOperation = async () => {
		const alert = await this.alertController.create({
			header: this.translocoService.translate("LOCATION_NOT_READY_FOR_OPERATION_ALERT__TITLE"),
			message: this.translocoService.translate("LOCATION_NOT_READY_FOR_OPERATION_ALERT__MESSAGE"),
			backdropDismiss: false,
			buttons: [this.translocoService.translate("LOCATION_NOT_READY_FOR_OPERATION_ALERT__OK")],
		});

		await alert.present();
	};
	private alertLocationNoLongerOpen = async () => {
		const alert = await this.alertController.create({
			header: this.translocoService.translate("LOCATION_IS_NOT_OPEN_ALERT__TITLE"),
			message: this.translocoService.translate("LOCATION_IS_NOT_OPEN_ALERT__MESSAGE"),
			backdropDismiss: false,
			buttons: [this.translocoService.translate("LOCATION_IS_NOT_OPEN_ALERT__OK")],
		});

		await alert.present();
	};
	private showErrorWhileClosingLocationAlert = async () => {
		const alert = await this.alertController.create({
			message: this.translocoService.translate("CLOSE_LOCATION__ERROR_MESSAGE"),
			buttons: [this.translocoService.translate("CLOSE_LOCATION__OK")],
			backdropDismiss: true,
		});

		await alert.present();
	};
	private showErrorWhileOpeningLocationAlert = async () => {
		const alert = await this.alertController.create({
			message: this.translocoService.translate("OPEN_LOCATION_ERROR_MESSAGE"),
			buttons: [this.translocoService.translate("OPERATOR_LOGIN__OK")],
			backdropDismiss: true,
		});

		await alert.present();
	};
	private listenForLanguageChanges = () => {
		this.locationStateQuery.languageAndOperator$.subscribe(([language, operator]) => {
			const availableLanguages = this.languageService.getAvailableLangs().map((x) => x.id);

			if (availableLanguages.includes(operator?.language)) {
				this.translocoService.setActiveLang(operator.language);
			} else if (availableLanguages.includes(language)) {
				this.translocoService.setActiveLang(language);
			} else {
				this.translocoService.setActiveLang("en");
			}
		});
	};

	public getLocationStatus = async (location: string) => {
		const environment = await this.environmentQuery.environment$.firstAsync();
		const endpoint = environment.api.url + "api/rideops/location-status";
		const response = await this.http
			.get<ApiResponseWithData<ServerStateResponse>>(endpoint, { params: { location: location } })
			.firstAsync();

		return response.data;
	};

	public updateLocationStatus = async (location: string) => {
		const environment = await this.environmentQuery.environment$.firstAsync();
		const endpoint = environment.api.url + "api/rideops/location-status";

		try {
			const response = await this.http
				.get<ApiResponseWithData<ServerStateResponse>>(endpoint, {
					params: { location: location },
				})
				.firstAsync();

			const storeUpdate: Partial<LocationState> = {
				...response.data,
				lastServerUpdate: new Date().toISOString(),
				lastUpdateFailed: false,
			};

			applyTransaction(() => {
				this.locationStateStore.update((state) => {
					//we do some state change checks here if its the same locations
					if (state.location?.id == location) {
						if (state.state == "Open" && storeUpdate.state != "Open" && this.router.url == "/dashboard") {
							this.alertLocationNoLongerOpen();
						}

						if (
							state.isClearedForOperation &&
							!storeUpdate.isClearedForOperation &&
							this.router.url == "/dashboard"
						) {
							this.alertNoLongerClearedForOperation();
						}
					}

					storeUpdate.location = {
						id: response.data.id,
						name: response.data.name,
						language: response.data.language,
						scannerCode: response.data.scannerCode,
					};

					return storeUpdate;
				});

				this.locationStateStore.setHasCache(true, { restartTTL: true });
				this.UiService.setDefaultCarts(storeUpdate.rideOpsSettings.dispatchUnits);
				this.UiService.setDefaultNumberGridAmount(storeUpdate.rideOpsSettings.dispatchUnitCapacity);

				if (response.data.rideOpsSettings.lockDeviceSettings) {
					this.UiService.setLockedUI(response.data.rideOpsSettings.deviceSettings);
				} else {
					this.UiService.setUnlockedUI();
				}
			});
		} catch (error) {
			this.locationStateStore.update({ lastUpdateFailed: true });
			throw error;
		}
	};
	activate() {
		if (this.isActivated) {
			return;
		}

		this.isActivated = true;

		combineLatest([
			this.locationStateQuery.selectHasCache(),
			this.locationStateQuery.location$,
			this.idleService.isInactive$,
		])
			.pipe(
				filter(
					([isCached, location, isInactive]) => isCached == false && isInactive == false && location != null,
				),
				switchMap(([_, location]) => from(this.updateLocationStatus(location.id))),
				retryBackoff({
					initialInterval: 100,
					maxInterval: 60000,
					resetOnSuccess: true,
				}),
			)
			.subscribe();
	}
}

interface ServerStateResponse {
	createdDowntimesAreBlocking: boolean;
	downtime: Downtime;
	state: "Open" | "Closed";
	stateLastUpdated: Date;
	operators: Operator[];
	requiredCompetencies: DocumentReference[];
	preopeningChecklist: string;
	rideOpsSettings: RideOpsSettingsWithDeviceSettings;
	locations: Location[];
	locationProperties?: string[];
	unitsAvailable: number;
	unitCapacity: number;
	language: string;
	isClearedForOperation: boolean;
	isClearedForOperationDetails: IsClearedForOperationDetails;
	noteTypes: NoteTypeReference[];
	noteTemplates: NoteTemplateReference[];
	id: string;
	name: string;
	scannerCode: string;
}

interface RideOpsSettingsWithDeviceSettings extends RideOpsSettings {
	deviceSettings: UIDeviceSettings;
}

export interface UIDeviceSettings {
	allowChangingDispatchesWhenClosingLocation: boolean;
	allowChangingRidersWhenClosingLocation: boolean;
	showQueueTimeWidget: boolean;
	showDispatchWidget: boolean;
	maximumRidersPerDispatch: number;
	dispatchWidgetType: "selector" | "grid";
	dispatchGridNumberDirection: "ascending" | "descending";
	dispatchMetric: "emptySeats" | "occupiedSeats";
}
