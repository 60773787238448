import { Injectable } from "@angular/core";
import { Store, StoreConfig } from "@datorama/akita";

@Injectable({ providedIn: "root" })
@StoreConfig({ name: "checklist", resettable: true })
export class ChecklistStateStore extends Store<ChecklistState> {
	private static createInitialState = (): ChecklistState => ({
		preopeningChecklistValidity: null,
		closingChecklistValidity: null,
	});
	constructor() {
		super(ChecklistStateStore.createInitialState());
	}
}

export interface ChecklistState {
	preopeningChecklistValidity: string;
	closingChecklistValidity: string;
}
