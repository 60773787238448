import { Component, Input, forwardRef } from "@angular/core";
import { Question } from "../../../checklist.interface";
import { TextareaCustomEvent } from "@ionic/angular";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
	selector: "app-comment",
	templateUrl: "./comment.component.html",
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CommentComponent),
			multi: true,
		},
	],
})
export class CommentComponent implements ControlValueAccessor {
	@Input() question: Question;
	showComment = false;
	commentModel = "";

	activateComment = () => {
		this.showComment = true;
	};

	onCommentChange = (event: Event) => {
		const value = (<TextareaCustomEvent>event).target.value;
		this.onTouched();
		this.onChange(value);
	};

	//form setup
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onChange = (_comment: string) => {};
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onTouched = () => {};
	disabled = false;

	writeValue(obj: string): void {
		this.commentModel = obj;
		this.showComment = this.commentModel?.length > 0;
	}

	registerOnChange(fn: (value: string) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}
