import { Injectable } from "@angular/core";
import { Store, StoreConfig } from "@datorama/akita";

export interface LocationState {
	state: "Open" | "Closed";
	createdDowntimesAreBlocking: boolean;
	downtime: Downtime;
	apiKey: string | null;
	location?: Location;
	operator: CurrentOperator;
	operators: Operator[];
	rideOpsSettings: RideOpsSettings;
	requiredCompetencies: DocumentReference[];
	requiredCompetenciesForAttendants: DocumentReference[];
	preopeningChecklist: string;
	closingChecklist: string;
	isClearedForOperation: boolean;
	isClearedForOperationDetails: IsClearedForOperationDetails;
	locationProperties: string[];
	language?: string;
	noteTypes: NoteTypeReference[];
	noteTemplates: NoteTemplateReference[];
	downtimeTemplates: DowntimeTemplateReference[];
	lastServerUpdate: string;
	lastUpdateFailed: boolean;
	lastQueueUpdate: LastQueueTimeUpdate;
	lastRidersUpdate: LastRidersUpdate;
	riders: number;
	dispatches: number;
}

export interface IsClearedForOperationDetails {
	isClearedForOperation: boolean;
	hasBlockingDowntime: boolean;

	assignments: {
		id: string;
		name: string;
		description: string;
		assignees: DocumentReference[];
		created: string;
	}[];

	checklists: {
		id: string;
		name: string;
		asset?: string;
		due?: string;
		end: string;
	}[];
}

export interface RidePosition {
	identifier: number;
	name: string;
	mandatory: boolean;
	description: string;
	requiredCompetencies: DocumentReference[];
	attendant?: RidePositionAttendant;
}

export interface RidePositionAttendant {
	loggedIn: string;
	user: {
		id: string;
		name: string;
		profileImage: string;
	};
}

export interface LastRidersUpdate {
	riders: number;
	lastUpdated: Date | string;
}

export interface LastQueueTimeUpdate {
	time: number;
	lastUpdated: Date | string;
}

export interface Downtime {
	description: string;
	id: string;
	state: "Open" | "Blocking";
	updated: string;
}

export interface NoteTypeReference extends DocumentReference {
	isDefault: boolean;
	isDeleted: boolean;
}

export interface NoteTemplateReference extends DocumentReference {
	color: string;
	noteType: string;
	description: string;
	name: string;
}

export interface DowntimeTemplateReference extends DocumentReference {
	description: string;
	color: string;
	categories: string[];
}

export interface Location extends DocumentReference {
	language: string;
	operators?: string[];
	scannerCode?: string;
}

export interface RideOpsSettings {
	dispatchUnitCapacity: number;
	dispatchUnits: number;
	allowStatisticsForOperator: boolean;
	dispatchUpdateWarningThreshold: number;
	queueTimeUpdateWarningThreshold: number;
	maximumQueueTimeMinutes: number;
	queueTimeStepSizeMinutes: number;
	lockDeviceSettings: boolean;
	minumumSecondsBetweenDispatches: number;
	messageForOperatorWhenNotReadyForOperation: string;
	messageForOperatorWhenNoCriticalForOperationChecklistsPlanned: string;
	positions: RidePosition[];
	attendants: Operator[];
}

export function createInitialState(): LocationState {
	return {
		state: "Closed",
		createdDowntimesAreBlocking: undefined,
		downtime: undefined,
		apiKey: undefined,
		location: undefined,
		operator: undefined,
		isClearedForOperation: false, //legacy
		isClearedForOperationDetails: {
			checklists: [],
			assignments: [],
			isClearedForOperation: false,
			hasBlockingDowntime: false,
		},
		locationProperties: [],
		operators: [],
		rideOpsSettings: {
			dispatchUnitCapacity: 1,
			dispatchUnits: 1,
			allowStatisticsForOperator: false,
			dispatchUpdateWarningThreshold: 15,
			queueTimeUpdateWarningThreshold: 15,
			maximumQueueTimeMinutes: 60,
			queueTimeStepSizeMinutes: 5,
			lockDeviceSettings: false,
			minumumSecondsBetweenDispatches: 0,
			messageForOperatorWhenNotReadyForOperation: "",
			messageForOperatorWhenNoCriticalForOperationChecklistsPlanned: "",
			positions: [],
			attendants: [],
		},
		requiredCompetencies: [],
		requiredCompetenciesForAttendants: [],
		preopeningChecklist: undefined,
		closingChecklist: undefined,
		noteTypes: [],
		noteTemplates: [],
		downtimeTemplates: [],
		lastServerUpdate: null,
		lastUpdateFailed: false,
		lastQueueUpdate: undefined,
		lastRidersUpdate: undefined,
		riders: 0,
		dispatches: 0,
	};
}

@Injectable({ providedIn: "root" })
@StoreConfig({
	name: "locationState",
	resettable: true,
	cache: {
		ttl: 120000,
	},
})
export class LocationStateStore extends Store<LocationState> {
	constructor() {
		super(createInitialState());
	}
}
